import { useKeyboardSize } from '@community-group/components';
import { useConnectedActions } from '@daangn/stackflow-connection-actions';
import { vars } from '@seed-design/design-token';
import { IconKeyboardHidingRegular } from '@seed-design/icon';
import { IconMentionRegular } from '@seed-design/icon';
import { IconLocationRegular } from '@seed-design/icon';
import { IconAddRegular } from '@seed-design/icon';
import { IconPollRegular } from '@seed-design/icon';
import React, { useEffect, useState } from 'react';
import { Controller } from 'react-hook-form';

import useShowPhotoContestInOngoingExerciseGroup from '@/components/group/PhotoContest/hooks/useShowPhotoContestInOngoingExerciseGroup';
import { PollFormActivityParamsJSON } from '@/components/group/Poll/pages';
import { useBridge } from '@/contexts/Bridge';
import { useDeviceConfig } from '@/contexts/DeviceConfig';
import { useFeatureFlag } from '@/hooks/useFeatureFlag';
import { usePlaceSearchClient } from '@/hooks/usePlaceSearchClient';
import { useQueryParams } from '@/stackflow/hooks/useQueryParams';

import { GroupPostFormProps } from '../GroupPostForm';
import { PhotoContextCheckbox } from '../PhotoContextCheckbox';
import AppBarNotice from './AppBarNotice';
import * as s from './index.css';
import PhotoButton from './PhotoButton';
import VideoButton from './VideoButton';

export const GroupPostFormAppBar = ({
  handleFocusTextarea,
  handleBlurTextarea,
  formHandler,
  mode = 'new',
  handleAddButton,
  mentionSuggestionsRef,
}: GroupPostFormProps & {
  handleFocusTextarea: () => void;
  handleBlurTextarea: () => void;
  mode?: 'new' | 'edit';
  handleAddButton?: () => void;
  mentionSuggestionsRef: React.RefObject<HTMLDivElement>;
}) => {
  const { postType, groupId = '0', from, calloutType } = useQueryParams();
  const { bridge } = useBridge();
  const { deviceConfig } = useDeviceConfig();
  const { keyboardHeight, isKeyboardOn } = useKeyboardSize(bridge);

  const { push } = useConnectedActions();
  const { openSearchPoiPage } = usePlaceSearchClient();

  const { setValue, watch, getValues } = formHandler;

  const handleLocationClick = async () => {
    if ((watch('poiStreams') ?? []).length > 0) {
      return bridge.openToast({
        toast: {
          body: '게시글에는 하나의 장소만 추가할 수 있어요.',
        },
      });
    }

    openSearchPoiPage({});
  };

  const handleMentionClick = () => {
    setValue('content', `${watch('content')} @`);

    handleFocusTextarea();
  };

  const handlePollClick = async () => {
    const poll = getValues('poll');
    const pollValues = (await push('GroupPollFormPage', {
      groupId,
      pollOptionValueJSONString: JSON.stringify({
        title: poll?.title ?? '',
        pollOptionTitles: poll?.options?.map((option) => option.title) ?? [],
        isMultiple: poll?.isMultiple ?? false,
      }),
    })) as PollFormActivityParamsJSON;

    setValue('poll', {
      title: pollValues.title,
      options: pollValues.pollOptionTitles.map((title) => ({ title })),
      isMultiple: pollValues.isMultiple,
    });
  };

  // TODO: 모임사진전
  const shownPhotoContest = useShowPhotoContestInOngoingExerciseGroup({
    groupId,
  });
  const photoContestVersion = useFeatureFlag('photoContestVersion');

  const [shownAddPhotoGuideHelpBubble, setShownAddPhotoGuideHelpBubble] = useState(false);

  const hidePhotoGuideHelpBubbleAfter3sec = () => {
    // 툴팁이 3초 후에 사라지도록 설정
    setTimeout(() => {
      setShownAddPhotoGuideHelpBubble(false);
    }, 3000);
  };

  const openRunningPhotoGuideBottomSheet = async () => {
    push('BottomSheet/RunningPhotoGuideBottomSheet', {});
  };

  useEffect(() => {
    const isPhotoContestFunnelEntryUser =
      mode === 'new' && shownPhotoContest && from === 'groupPhotoContest';
    const isHeroImageFunnelEntryUser = from === 'HeroImage' || calloutType === 'first-post-mission';

    // 3회차 사진전인 경우에는 바텀시트가 있어 헬프버블을 보여주지 않는다.
    if (isPhotoContestFunnelEntryUser && photoContestVersion === 3) {
      openRunningPhotoGuideBottomSheet();
      return;
    }

    if (isPhotoContestFunnelEntryUser || isHeroImageFunnelEntryUser) {
      setTimeout(() => {
        setShownAddPhotoGuideHelpBubble(true);
        hidePhotoGuideHelpBubbleAfter3sec();
      }, 500);
    }
  }, []);

  const useVideoUpload = useFeatureFlag('useVideoUpload');

  return (
    <div
      className={s.Wrapper}
      onMouseDown={(e) => {
        e.preventDefault();
        e.stopPropagation();
      }}
    >
      {shownPhotoContest ? (
        <div style={{ width: '100%', padding: '4px 16px' }}>
          <Controller
            name="isPhotoContestEntry"
            control={formHandler.control}
            render={({ field: { value, onChange } }) => {
              const handleOnChange = (isSelected: boolean) => {
                onChange(isSelected);
                if (isSelected && photoContestVersion === 3) {
                  openRunningPhotoGuideBottomSheet();
                }
              };
              return (
                <PhotoContextCheckbox
                  isSelected={value ?? false}
                  onChange={handleOnChange}
                  disabled={mode !== 'new' || watch('isGroupOnly') === true}
                  displayHelpBubble={true}
                />
              );
            }}
          />
        </div>
      ) : (
        <AppBarNotice mode={mode} watch={watch} />
      )}

      <div
        className={s.AppBarWrapper({
          isOpenKeyboard: isKeyboardOn,
          isAndroid: deviceConfig.theme === 'android',
        })}
        ref={mentionSuggestionsRef}
        onClick={handleFocusTextarea}
      >
        <div className={s.LeftSide}>
          {mode === 'new' && postType !== 'meetupReview' && handleAddButton && (
            <button
              className={s.IconButton}
              onClick={(event) => {
                event.stopPropagation();
                handleBlurTextarea();
                handleAddButton();
                setShownAddPhotoGuideHelpBubble(false);
              }}
            >
              <IconAddRegular width={20} height={20} color={vars.$scale.color.gray600} />
            </button>
          )}

          <ul className={s.FeatureList}>
            <PhotoButton
              formHandler={formHandler}
              shownAddPhotoGuideHelpBubble={shownAddPhotoGuideHelpBubble}
              setShownAddPhotoGuideHelpBubble={setShownAddPhotoGuideHelpBubble}
            />
            {useVideoUpload && <VideoButton formHandler={formHandler} />}

            <button className={s.FeatureButton} onClick={handleLocationClick}>
              <IconLocationRegular width={24} height={24} color={vars.$scale.color.gray600} />
            </button>

            <button className={s.FeatureButton} onClick={handleMentionClick}>
              <IconMentionRegular size={24} color={vars.$scale.color.gray600} />
            </button>

            {mode === 'new' && (
              <div className={s.IconPollRegularWrapper}>
                <button className={s.FeatureButton} onClick={handlePollClick}>
                  <IconPollRegular width={24} height={24} color={vars.$scale.color.gray600} />
                </button>
              </div>
            )}
          </ul>
        </div>
        {keyboardHeight > 0 && (
          <button
            className={s.KeyboardHidingButton}
            onClick={(event) => {
              event.stopPropagation();
              handleBlurTextarea();
            }}
          >
            <IconKeyboardHidingRegular width={24} height={24} color={vars.$scale.color.gray600} />
          </button>
        )}
      </div>
    </div>
  );
};
