import { Video } from '@community-group/api/lib/group/models';
import { Spacing, Typography } from '@community-group/components';
import { vars } from '@seed-design/design-token';
import { IconPlayFill } from '@seed-design/icon';
import React, { useMemo } from 'react';

import * as s from './item.css';

type Props = {
  video: Video;
  aspectRatio: string;
  alt?: string;
  isMultipleItem?: boolean;
};

function VideoItem({ video, alt = '게시글 첨부 영상', isMultipleItem }: Props) {
  const durationText = useMemo(() => {
    const duration = video.duration;
    const hours = Math.floor(duration / 3600);
    const minutes = Math.floor((duration % 3600) / 60);
    const seconds = duration % 60;

    const minText = String(minutes).padStart(2, '0');
    const secText = String(seconds).padStart(2, '0');

    if (hours > 0) {
      return `${hours}:${minText}:${secText}`;
    }
    return `${minText}:${secText}`;
  }, [video.duration]);

  const videoAspectRatio = useMemo(() => {
    //세로형이면 정방형, 가로형이면 16:9
    const isPortrait = video.width <= video.height;
    return isPortrait ? '1/1' : '16/9';
  }, [video.width, video.height]);

  return (
    <div className={s.ImageContainer}>
      <div className={s.ImageBorder} />
      <img
        src={video.thumbnailUrl}
        className={s.Image}
        style={{ aspectRatio: isMultipleItem ? '1/1' : videoAspectRatio }}
        alt={alt}
      />
      {isMultipleItem && (
        <div className={s.DurationText}>
          <Typography typography={'label6Regular'} color="gray100">
            {durationText}
          </Typography>
        </div>
      )}
      {!isMultipleItem && (
        <div className={s.VideoInfoWrapper}>
          <div className={s.PlayButtonWrapper}>
            <IconPlayFill size={26} color={vars.$static.color.staticWhite} />
          </div>
          <Spacing height={8} />
          <div className={s.PlayInfoDurationText}>
            <Typography typography={'caption2Regular'} color="onPrimary">
              {durationText}
            </Typography>
          </div>
        </div>
      )}
    </div>
  );
}

export default VideoItem;
