import { vars } from '@seed-design/design-token';
import { IconCloseFill, IconRetryRegular } from '@seed-design/icon';
import clsx from 'clsx';
import React, { HTMLAttributes, useMemo } from 'react';

import { Spinner, Typography } from '../../base';
import { VerticalSpacer } from '../../Spacer';
import { FileUploadState } from '../types/Video';
import * as s from './VideoFieldItem.css';

interface Props extends HTMLAttributes<HTMLDivElement> {
  video: FileUploadState;
  onRemoveClick: (video: FileUploadState) => void;
  onRetryClick?: (video: FileUploadState) => void;
}

const VideoFieldItem: React.FC<Props> = ({ video, onRemoveClick, onRetryClick, ...props }) => {
  const videoDurationText = useMemo(() => {
    const duration = video.uploadedVideoInfo?.duration;
    if (!duration) return undefined;

    const minutes = Math.floor(duration / 60);
    const seconds = Math.floor(duration % 60);

    const minutesString = minutes.toString().padStart(2, '0');
    const secondsString = seconds.toString().padStart(2, '0');
    return `${minutesString}:${secondsString}`;
  }, [video.uploadedVideoInfo?.duration]);

  return (
    <>
      <div {...props} className={clsx([props.className, s.wrapper])}>
        <div
          className={s.removeWrapper}
          onClick={(event) => {
            event.stopPropagation();
            onRemoveClick(video);
          }}
          role="button"
          aria-label="Remove video"
        >
          <IconCloseFill size={12} color={vars.$scale.color.gray00} />
        </div>
        {video.status === 'failed' && (
          <div
            className={s.statusWrapper({ shown: true })}
            onClick={() => onRetryClick?.(video)}
            role="button"
            aria-label="Retry upload"
          >
            <IconRetryRegular width={24} height={24} color={vars.$static.color.staticWhite} />
            <Typography color="staticWhite" typography="label4Bold">
              재시도
            </Typography>
          </div>
        )}
        {video.status === 'uploading' && (
          <div className={s.statusWrapper({ shown: true })}>
            <Spinner variant="gray" size="small" />
          </div>
        )}
        <img
          className={s.image}
          src={video.thumbnail}
          alt={`Thumbnail of video ${video.id}`}
          width={64}
          height={64}
          loading="lazy"
        />
        {videoDurationText && (
          <div className={s.duration}>
            <Typography color="staticWhite" typography="label6Regular">
              {videoDurationText}
            </Typography>
          </div>
        )}
      </div>
      <VerticalSpacer size={8} />
    </>
  );
};

export default React.memo(VideoFieldItem);
