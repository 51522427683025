export const generateThumbnailFromVideo = (file: File): Promise<string> => {
  return new Promise((resolve, reject) => {
    const videoElement = document.createElement('video');
    document.body.appendChild(videoElement);
    window.URL = window.URL || window.webkitURL;
    videoElement.src = window.URL.createObjectURL(file);
    videoElement.crossOrigin = 'anonymous'; // CORS 문제 해결 시도
    videoElement.muted = true; // 모바일 웹뷰에서 자동재생을 허용하기 위해 무음 처리
    videoElement.playsInline = true; // iOS 웹뷰에서 인라인 재생 허용
    videoElement.autoplay = true; // 자동 재생 허용
    videoElement.play(); // 비디오 재생

    // 비디오가 로드되면 특정 시간으로 이동
    videoElement.onloadedmetadata = () => {
      videoElement.currentTime = 0.1; // 썸네일로 캡처할 시간 설정 (0.1초 지점)
    };

    // 프레임이 정확히 렌더링된 후 콜백 실행
    const drawFrame = () => {
      const canvas = document.createElement('canvas');
      canvas.width = videoElement.videoWidth;
      canvas.height = videoElement.videoHeight;

      const context = canvas.getContext('2d');
      if (context) {
        context.drawImage(videoElement, 0, 0, canvas.width, canvas.height);
        canvas.toBlob((blob) => {
          document.body.removeChild(videoElement); // 비디오 엘리먼트 제거
          if (blob) {
            const thumbnailUrl = URL.createObjectURL(blob);
            resolve(thumbnailUrl);
          } else {
            reject('Thumbnail creation failed');
          }
        }, 'image/png');
      } else {
        reject('Canvas context is not available');
      }
    };

    // 비디오가 특정 시간에 도달했을 때
    videoElement.onseeked = () => {
      // requestVideoFrameCallback이 지원되는 경우 사용
      if ('requestVideoFrameCallback' in videoElement) {
        (videoElement as HTMLVideoElement)?.requestVideoFrameCallback(() => {
          drawFrame(); // 프레임이 렌더링된 후 캔버스에 그림
          videoElement.pause(); // 프레임을 그린 후 비디오 일시 정지
        });
      } else {
        // requestVideoFrameCallback을 지원하지 않는 경우 'playing' 이벤트 사용
        (videoElement as HTMLVideoElement)?.addEventListener('playing', drawFrame, { once: true });
      }
    };

    videoElement.onerror = () => {
      document.body.removeChild(videoElement);
      reject('Video failed to load');
    };

    // 비디오 처리 완료 후 비디오 엘리먼트를 DOM에서 제거
    videoElement.onended = () => {
      document.body.removeChild(videoElement);
    };
  });
};
