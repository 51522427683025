import { FileUploadState, UploadVideoResponse, VideoUploadErrorType } from '../types/Video';
import { generateThumbnailFromVideo } from '../utils/thumbnail';
import { DEFAULT_VALIDATION_CONFIG, ValidationConfig } from '../utils/video';
import { ManageUploadStateProps, useManageUploadState } from './useManageUploadState';
import { usePickVideoFiles } from './usePickVideoFiles';
import { useUploadVideoFile } from './useUploadVideoFile';
import { useVideoValidation } from './useVideoValidation';

export interface VideoFileHandlerProps {
  validationConfig?: Partial<ValidationConfig>;
  uploadVideo: (params: { file: File }) => Promise<UploadVideoResponse>;
  onUploadStart?: (file: File) => void;
  onUploadSuccess?: (file: File, response: UploadVideoResponse) => void;
  onUploadError?: (type: VideoUploadErrorType) => (file: File, error: Error) => void;
  externalStateSelector?: ManageUploadStateProps['externalStateSelector'];
}

export const useVideoFileHandler = ({
  validationConfig = DEFAULT_VALIDATION_CONFIG,
  externalStateSelector,
  uploadVideo,
  onUploadStart,
  onUploadSuccess,
  onUploadError,
}: VideoFileHandlerProps) => {
  // 파일 상태 관리 -> 외부 상태와 동기화
  const { uploadedFiles, addUpload, updateUploadStatus, removeUpload } = useManageUploadState({
    externalStateSelector,
  });

  // 비디오 파일 선택
  const { pickVideoFiles } = usePickVideoFiles({});

  // 비디오 파일 유효성 검사
  const { validateVideoFile, validateFileCount } = useVideoValidation({
    validationConfig,
    onUploadError,
  });

  // useUploadVideoFile 훅 사용
  const { startUpload } = useUploadVideoFile({
    uploadVideo,
    updateUploadStatus,
    onUploadStart,
    onUploadSuccess,
    onUploadError,
  });

  // 비디오 파일 선택 및 업로드 로직
  const handleVideoFileSelection = async () => {
    const selectedFiles = await pickVideoFiles();
    if (!selectedFiles || selectedFiles.length === 0) return;

    // 업로드 가능한 파일 수 계산
    const { validFiles } = validateFileCount(selectedFiles, uploadedFiles.length);

    for (const file of validFiles) {
      // 1. 파일 유효성 검사
      const validationMessage = await validateVideoFile(file);
      if (validationMessage) return;

      // 2. 썸네일 생성
      const thumbnail = await generateThumbnailFromVideo(file);

      // 3. 상태에 추가
      const id = `${file.name}-${Date.now()}`;
      const newFileState: FileUploadState = {
        id,
        file,
        thumbnail,
        status: 'idle',
        progress: 0,
      };
      addUpload(newFileState);

      // 4. 파일 업로드 시작
      startUpload(newFileState);
    }
  };

  // 파일 업로드 취소
  const cancelVideoUpload = (fileState: FileUploadState) => {
    removeUpload(fileState.id);
  };

  // 파일 업로드 재시도
  const retryVideoUpload = (fileState: FileUploadState) => {
    startUpload(fileState);
  };

  return {
    selectedFiles: uploadedFiles,
    handleVideoFileSelection,
    cancelVideoUpload,
    retryVideoUpload,
  };
};
