import { useVideoFileHandler, VideoFileHandlerProps } from '@community-group/components';
import { UseFormReturn } from 'react-hook-form';

import { useUploadVideo } from '@/api/base/uploadVideo';
import { useFetchInstance } from '@/api/hooks/instance/useFetchInstance';

import { GroupPostFormHandlerProps } from '../components/GroupPostForm';

type Props = {
  formHandler: UseFormReturn<GroupPostFormHandlerProps>;
} & Partial<VideoFileHandlerProps>;

const MAX_UPLOAD_VIDEO_COUNT = 10;

function useHandleUploadVideo({ formHandler, ...rest }: Props) {
  const { watch, setValue, getValues } = formHandler;

  const fetchInstance = useFetchInstance();
  const { mutateAsync: mutateUploadVideo } = useUploadVideo({ fetchInstance });

  const uploadedImagesCount = watch('images')?.length ?? 0;

  return useVideoFileHandler({
    validationConfig: {
      maxVideoCount: MAX_UPLOAD_VIDEO_COUNT - uploadedImagesCount,
    },
    externalStateSelector: {
      externalState: watch('uploadVideos'),
      externalStateHandler: (updater) => {
        const currentUploads = getValues('uploadVideos');
        setValue('uploadVideos', updater(currentUploads));
      },
    },
    uploadVideo: async ({ file }) => {
      const data = await mutateUploadVideo({ file });
      console.log('data', data);
      return data;
    },
    ...rest,
  });
}

export default useHandleUploadVideo;
