import { VideoUploadErrorType } from '../types/Video';

export interface ValidationConfig {
  maxVideoCount: number;
  maxVideoSizeMB: number;
  maxVideoDurationHours: number;
  allowedFileTypesRegex: string;
}

export const DEFAULT_VALIDATION_CONFIG = {
  maxVideoCount: 10, // 10개 - 초과시 초과되는 파일부터 invalid
  maxVideoSizeMB: 1024, // 1GB
  maxVideoDurationHours: 10, // 10 minutes
  allowedFileTypesRegex: '^video/(3gpp|3gp2|3gpp2|mpeg|mp4|x-m4v|quicktime|webm)',
};

export const validationVideoFile = async (
  file: File,
  config: ValidationConfig
): Promise<{ type: VideoUploadErrorType; message: string } | undefined> => {
  if (!file) {
    return { type: 'undefinedFile', message: '파일이 존재하지 않습니다.' };
  }

  if (!validateVideoFileType(file.type, config.allowedFileTypesRegex)) {
    return { type: 'fileFormat', message: '지원하지 않는 포맷이에요.' };
  }

  if (byteToMegaByte(file.size) >= config.maxVideoSizeMB) {
    return {
      type: 'fileSize',
      message: `동영상 크기는 ${config.maxVideoSizeMB}MB까지 가능합니다.`,
    };
  }

  const duration = await getVideoDuration(file);
  if (duration && duration > config.maxVideoDurationHours * 60 * 60) {
    return {
      type: 'videoDuration',
      message: `동영상 재생시간은 최대 ${config.maxVideoDurationHours}시간까지 가능합니다.`,
    };
  }

  return undefined;
};

const validateVideoFileType = (type: string, allowedFileTypesRegex: string): boolean => {
  return new RegExp(allowedFileTypesRegex).test(type);
};

const byteToMegaByte = (bytes: number): number => {
  return bytes / (1024 * 1024);
};

export const getVideoDuration = (file: File) => {
  return new Promise<number | null>((resolve, reject) => {
    try {
      const video = document.createElement('video');
      video.preload = 'metadata';

      video.onloadedmetadata = function () {
        window.URL.revokeObjectURL(video.src);
        resolve(video.duration);
      };

      video.onerror = function () {
        window.URL.revokeObjectURL(video.src);
        resolve(null);
      };

      video.src = window.URL.createObjectURL(file);
    } catch (e) {
      reject(e);
    }
  });
};
