import { Image, Video } from '@community-group/api/lib/group/models';

export const isVideo = (media: Image | Video): media is Video => {
  return 'videoUrl' in media && media.videoUrl.length !== 0;
};

export const isImage = (media: Image | Video): media is Image => {
  return 'url' in media && media.url.length !== 0;
};

export const videoDurationText = (duration: number) => {
  const min = Math.floor(duration / 60);
  const sec = duration % 60;
  return `${min.toString().padStart(2, '0')}:${sec.toString().padStart(2, '0')}`;
};
