import { Video } from '@community-group/api/lib/group/models';
import { Spinner, VideoPlayer, VideoPlayInfoButton } from '@community-group/components';
import { useCallback, useMemo, useRef, useState } from 'react';

import * as s from './VideoPlayerItem.css';

const VideoPlayerItem = ({ video }: { video: Video }) => {
  const videoRef = useRef<HTMLVideoElement>(null);

  const [playerState, setPlayerState] = useState<'playing' | 'paused' | 'loading' | 'ready'>(
    'loading'
  );

  const videoUrl = video.videoUrl;
  const thumbnailUrl = video.thumbnailUrl;

  const ratio = video ? video.width / video.height : 1;

  // 세로형 이미지인 경우 비율이 0.75 (3:4)보다 작으면 3:4 비율 적용, 그렇지 않으면 원래 비율 사용
  const aspectRatio = ratio < 0.75 ? '3 / 4' : `${ratio}`;

  const durationText = useMemo(() => {
    if (!video.duration) return '';
    const minutes = Math.floor(video.duration / 60);
    const seconds = Math.floor(video.duration % 60);
    return `${minutes.toString().padStart(2, '0')}:${seconds.toString().padStart(2, '0')}`;
  }, [video.duration]);

  const renderHiddenVideoContents = useCallback(() => {
    if (!video) return <Spinner />;

    return (
      <div
        className={s.ContentsWrapper}
        style={{
          aspectRatio,
        }}
      >
        <VideoPlayer
          controls
          src={videoUrl}
          ref={videoRef}
          className={s.ContentsWrapper}
          onCanPlay={() => {
            if (playerState === 'loading') {
              setPlayerState('ready');
            }
          }}
          onPlay={() => {
            setPlayerState('playing');
          }}
          onPause={() => {
            setPlayerState('paused');
          }}
        />
      </div>
    );
  }, [video, aspectRatio, videoUrl, playerState]);

  return (
    <div
      className={s.OuterWrapper}
      style={{
        aspectRatio,
      }}
    >
      <div
        className={s.InnerWrapper}
        style={{
          aspectRatio,
          zIndex: playerState === 'playing' ? -1 : 10,
        }}
        onClick={() => {
          const videoEl = videoRef.current as HTMLVideoElement;
          if (!videoEl) return;
          videoEl?.play();
          videoEl?.requestFullscreen();
        }}
      >
        <div className={s.VideoInfoWrapper}>
          <VideoPlayInfoButton durationText={durationText} />
        </div>
        <img
          src={thumbnailUrl}
          style={{ width: '100%', height: '100%', objectFit: 'cover', objectPosition: 'center' }}
        />
      </div>

      {renderHiddenVideoContents()}
    </div>
  );
};

export default VideoPlayerItem;
