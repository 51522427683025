import React, { forwardRef, LegacyRef, VideoHTMLAttributes } from 'react';

import * as s from './VideoPlayer.css';

type Props = {
  src: string;
  videoType?: React.SourceHTMLAttributes<HTMLSourceElement>['type'];
} & VideoHTMLAttributes<HTMLVideoElement>;

const VideoPlayer = forwardRef(
  ({ src, videoType = 'video/mp4', ...props }: Props, ref: LegacyRef<HTMLVideoElement>) => {
    return (
      <video controls ref={ref} {...props} className={s.Video}>
        <source src={src} type={videoType} />
      </video>
    );
  }
);

export default VideoPlayer;
